import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import AxiosService from "../../components/AxiosService";

const initialCurrentFacture = [
    { keyId: 1, acte: "Consultation", code: "cst", prixTvac: 0, sub: true, isSelected: false, sousActes: [
           { keyId: 1, code: "cst_cst",  acte: "Consultation", prixTvac: 35, isSelected: false, sub: false},
            { keyId: 2, code: "cst_inj", acte: "Injection", prixTvac: 14, isSelected: false, sub: false},
            {keyId: 3, code: "cst_rx", acte: "radiographie", prixTvac: 50, isSelected: false,sub: false},
            {keyId: 4, code: "cst_echo", acte: "echographie", prixTvac: 50, isSelected: false,sub: false},
            {keyId: 5, code: "cst_pds", acte: "prise de sang", prixTvac: 13.5, isSelected: false,sub: false},
            {keyId: 6, code: "cst_mic", acte: "microscopie", prixTvac: 14, isSelected: false,sub: false},
            {keyId: 7, code: "cst_oph", acte: "ophtalmologie", prixTvac: 10, isSelected: false,sub: false},
            {keyId: 8, code: "cst_con", acte: "controle", prixTvac: 15, isSelected: false,sub: false},
            {keyId: 9, code: "cst_ref", acte: "retrait fils", prixTvac: 15, isSelected: false,sub: false},
            {keyId: 10, code: "cst_puc", acte: "puce", prixTvac: 40, isSelected: false,sub: false},
            {keyId: 11, code: "cst_dom", acte: "visite à domicile", prixTvac: 55, isSelected: false,sub: false},
            {keyId: 12, code: "cst_euth_cab", acte: "Eutha Cabinet", prixTvac: 50, isSelected: false,sub: false},
            {keyId: 13, code: "cst_euth_dom", acte: "Eutha Domicile", prixTvac: 81, isSelected: false,sub: false},
            {keyId: 14, code: "cst_euth_urg", acte: "Eutha Urgence Cabinet", prixTvac: 75, isSelected: false,sub: false},
            {keyId: 15, code: "cst_euth_urg_dom", acte: "Eutha Urgence Domicile", prixTvac: 105, isSelected: false,sub: false}


        ]
    },

    { keyId: 2, acte: "Chirurgie", code: "chi", prixTvac: 0, sub: true, isSelected: false, subType: "chir", sousActes: [
            { keyId: 1, code: "chi_chi",  acte: "Chirurgie", prixTvac: 150, isSelected: false, sub: false},
            { keyId: 2, code: "chi_ane",  acte: "Anesthésie seule", prixTvac: 45, isSelected: false, sub: false},
            { keyId: 3, code: "chi_cas_chat_mal",  acte: "Castration chat", prixTvac: 75, isSelected: false, sub: false},
            { keyId: 4, code: "chi_cas_chat_fem",  acte: "Sterilisation chatte", prixTvac: 180, isSelected: false, sub: false},
            { keyId: 5, code: "chi_cas_chien_10",  acte: "Castration chien <10kg", prixTvac: 180, isSelected: false, sub: false},
            { keyId: 6, code: "chi_cas_chien_30",  acte: "Castration chien <30kg", prixTvac: 200, isSelected: false, sub: false},
            { keyId: 7, code: "chi_cas_chien_99",  acte: "Castration chien >30kg", prixTvac: 220, isSelected: false, sub: false},
            { keyId: 8, code: "chi_ste_chienne_10",  acte: "Sterilisation chienne <10kg", prixTvac: 350, isSelected: false, sub: false},
            { keyId: 9, code: "chi_ste_chienne_25", acte: "Sterilisation chienne <25kg", prixTvac: 405, isSelected: false, sub: false},
            { keyId: 10, code: "chi_ste_chienne_99", acte: "Sterilisation chienne >25kg", prixTvac: 455, isSelected: false, sub: false},
            { keyId: 11, code: "chi_cas_lapin", acte: "Castration lapin", prixTvac: 85, isSelected: false, sub: false},
            { keyId: 12, code: "chi_OVH_lapine", acte: "OVH lapine", prixTvac: 180, isSelected: false, sub: false},
            { keyId: 13, code: "chi_det_chat", acte: "Détartrage chat", prixTvac: 110, isSelected: false, sub: false},
            { keyId: 14, code: "chi_det_chat_eden", acte: "Détartrage chat avec édentation", prixTvac: 130, isSelected: false, sub: false},
            { keyId: 15, code: "chi_det_chien", acte: "Détartrage chien", prixTvac: 150, isSelected: false, sub: false},
            { keyId: 16, code: "chi_det_chien_eden", acte: "Détartrage chien avec édentation", prixTvac: 180, isSelected: false, sub: false},


    ]},
    { keyId: 3, acte:"Hospitalisation", code: "hos", prixTvac: 10, isSelected: false, sub: true, sousActes: [
            { keyId: 1, code: "hos_hos",  acte: "hospitalisation", prixTvac: 30, isSelected: false, sub: false},
            { keyId: 2, code: "hos_perf",  acte: "mise sous perfusion", prixTvac: 30, isSelected: false, sub: false},
            { keyId: 3, code: "chi_perfprod",  acte: "Produi perfusion", prixTvac: 10, isSelected: false, sub: false},
            { keyId: 4, code: "chi_rx",  acte: "ex cpl: Rx", prixTvac: 50, isSelected: false, sub: false},
            { keyId: 5, code: "chi_ech",  acte: "ex cpl: echo", prixTvac: 50, isSelected: false, sub: false},
            { keyId: 6, code: "chi_pds",  acte: "ex cpl: prise de sang", prixTvac: 13.5, isSelected: false, sub: false},
            { keyId: 7, code: "chi_ali",  acte: "Alimentation", prixTvac: 35, isSelected: false, sub: false},
            { keyId: 8, code: "chi_inj",  acte: "Injection", prixTvac: 14, isSelected: false, sub: false},
        ]
    },
    { keyId: 4, acte: "Vaccination", code: "vac", prixTvac: 0, isSelected: false, sub: true, subType: "vac", sousActes: [
        { keyId: 1, code: "vac_chien_base",  acte: "Vaccin base", prixTvac: 44, isSelected: false, sub: false},
        { keyId: 2, code: "vac_chien_rappel",  acte: "vaccin rappel", prixTvac: 29, isSelected: false, sub: false},
        { keyId: 3, code: "cst_puc", acte: "puce", prixTvac: 40, isSelected: false,sub: false},
        ]},
    { keyId: 5, acte: "Medicaments", code: "inj", prixTvac: 0, isSelected: false, sub: true, subType: "inj", sousActes: [
        //{ id: 1, nom: "bhuu", dose: "3", unite: "ml", prixTvac: 75, sub: false}
    ]},
    { keyId: 6, acte: "Alimentation", code: "ali", prixTvac: 0, isSelected: false, sub: true, subType: "ali", sousActes: [
            //{ id: 1, nom: "bhuu", dose: "3", unite: "ml", prixTvac: 75, sub: false}
    ]},
    { keyId: 7, acte: "Autres", code: "aut", prixTvac: 0, isSelected: false, sub: true, subType: "aut", sousActes: [

    ]}
]

const consultationState = {
    consultations: [],
    savingConsultation: false,
    currentConsultation: {},
    currentFacture: initialCurrentFacture,
    factureInfo : {},

    factures: [],
    selectedFacture : {}
};

export const getAnimalConsulations = createAsyncThunk("consultationStore/getAnimalConsultation", async (animalId, thunkAPI) => {
    const response = await AxiosService.get( '/api/v1/consultation/getAnimalConsultations?animalId='+animalId, {withCredentials: true});
    return response.data;
});

export const getConsultation = createAsyncThunk("consultationStore/getConsultation", async (consultationId, thunkAPI) => {
   const response = await AxiosService.get('/api/v1/consultation/'+consultationId, {withCredentials: true});
   return response.data;
});

export const getConsultationFromFacture = createAsyncThunk("consultationStore/getConsultationFromFacture", async (factureId, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/factures/'+factureId+'/consultation', {withCredentials: true});
    return response.data;
});

export const getFactureFromConsultation = createAsyncThunk("consultationStore/getFactureFromConsultation", async (consultationId, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/consultation/'+consultationId+'/facture', {withCredentials: true});
    return response.data;
});

export const getFacture = createAsyncThunk("consultationStore/getFacture", async (factureId, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/factures/' + factureId, {withCredentials: true});
    return response.data;
});

export const getAllFactures = createAsyncThunk("consultationStore/getAllFactures", async (payload, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/factures', {withCredentials: true});
    return response.data;

});

export const getAllVentes = createAsyncThunk("consultationStore/getAllVentes", async (clientId, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/factures/ventes/'+clientId, {withCredentials: true});
    return response.data;

});

export const saveFacture = async (payload) => {
    try {
        const response = await AxiosService.post("/api/v1/consultation/factures", payload, {withCredentials: true});
        return response.data;
    }catch (e) {
        throw Error;
    }
}

export const saveFactureInfo = async (payload) => {
    try {
        const response = await AxiosService.post("/api/v1/factures/"+payload.id+"/onlyFactureInfo", payload, {withCredentials: true});
        return response.data;
    }catch (e) {
        throw Error;
    }
}

export const saveConsultation = async (payload) => {
    try {
        const response = await AxiosService.post("/api/v1/consultation", payload, {withCredentials: true});
        return response.data;
    }catch (e) {
        throw Error;
    }
}

export const deleteConsultation = async(payload) => {
    try {
        await AxiosService.delete("/api/v1/consultation/" + payload);
    }catch (e) {
        throw Error;
    }
}

const detailsFactures = (state, payload) => {
    let _factureInfo = {...payload};
    delete _factureInfo.details;
    state.factureInfo = _factureInfo;
    if (!_factureInfo.numFacture) {
        state.factureInfo.isBrouillon = true;
    }

    let _details = payload.details ? [...payload.details] : [] ;
    let _newDetails = initialCurrentFacture.map(acte => {
        let _newActe = _details.find(_det => _det.code === acte.code);
        if (_newActe) {
            if (_newActe.sousActes && _newActe.sousActes.length > 0) {
                _newActe.isSelected = true;
            }
            _newActe.keyId = acte.keyId;
            let _sousActes = acte.sousActes
                .map(_sousActe => {
                    let _newSousActe = _newActe.sousActes.find(_det => _det.code === _sousActe.code);
                    if (_newSousActe) {
                        _newSousActe.isSelected = true;
                        _newSousActe.keyId = _sousActe.keyId;
                        return _newSousActe;
                    }else {
                        return _sousActe;
                    }
                });

            ["cst_medic", "chi_medic", "hos_medic", "vac_medic", "aut", "ali_sub", "inj_sub"].forEach(_code => {
                if (_newActe.sousActes.find(_det => _det.code === _code) !== undefined) {
                    _sousActes = _sousActes.concat(_newActe.sousActes.filter(_det => _det.code === _code).map(_det => {_det.isSelected = true; return _det;}));
                }
            })

            _newActe.sousActes = _sousActes.sort((a,b) => a.keyId > b.keyId ? 1: -1);
            return _newActe;
        }else {
            return acte;
        }
    })

    state.currentFacture = subTotalCalculations(_newDetails, state);
}

const subTotalCalculations = (currentFactureParam, state) => {
    let _currentFacture = [...currentFactureParam];
    let total = 0;
    let totalAlimInj = 0;
    let totalRemise = 0;
    _currentFacture = _currentFacture.map(acte => {
        let _acte = {...acte};
        if (_acte.sub ) {
            _acte.prixTvac = 0;
        }
        if (_acte.sub ) {
            _acte.sousActes.filter(sousActe => sousActe.isSelected).forEach(sousActe => {
                if (_acte.code === "ali" || _acte.code === "inj") {
                    _acte.prixTvac += parseFloat((sousActe.prixTvac * sousActe.qty - (sousActe.remise ? sousActe.remise : 0)).toFixed(2));
                    total += parseFloat((sousActe.prixTvac * sousActe.qty - (sousActe.remise ? sousActe.remise : 0)).toFixed(2));
                    totalAlimInj += parseFloat((sousActe.prixTvac * sousActe.qty).toFixed(2));
                    totalRemise += sousActe.remise;
                }else if (_acte.code === "vac") {
                    _acte.prixTvac += sousActe.prixTvac * (sousActe.qty ? sousActe.qty : 1);
                    total += sousActe.prixTvac * (sousActe.qty ? sousActe.qty : 1);
                }else {
                    _acte.prixTvac += sousActe.prixTvac;
                    total += sousActe.prixTvac;
                }

            })
        }
        return _acte;
    })
    state.factureInfo.total = total;
    state.factureInfo.totalAlimInj = totalAlimInj;
    state.factureInfo.totalRemise = totalRemise;
    return _currentFacture;
}

const consultationSlice = createSlice({
    name: "consultationStore",
    initialState: consultationState,
    reducers: {
        setCurrentConsultation: (state, action) => {
            state.currentConsultation = action.payload;
            state.consultations = state.consultations.map(consultation => {
                consultation.isSelected = consultation.id === action.payload.id;
                return consultation;
            })
        },
        clearConsultations: (state, action) => {
            state.currentConsultation = {};
            state.consultations = [];
        },
        clearCurrentConsultation: (state, action) => {
            state.currentConsultation = {};
        },
        setSavingConsultationState : (state, action) => {
            state.savingConsultation = action.payload;
        },
        setCurrentFacture: (state, action) => {
            state.currentFacture = subTotalCalculations(action.payload, state);
        },
        setFactureInfo: (state, action) => {
            state.factureInfo = action.payload;
        },
        resetCurrentFacture: (state, action) => {
            state.factureInfo ={};
            state.currentFacture = subTotalCalculations(initialCurrentFacture, state);
        },
        setSelectedFacture : (state, action) => {
            state.selectedFacture = action.payload;
        },
        resetFactures: (state, action) => {
            state.factures = [];
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getAnimalConsulations.fulfilled, (state, { payload }) => {
                state.consultations = payload;
                state.currentConsultation = {};
            })
            .addCase(getConsultation.fulfilled, (state, {payload}) => {
                state.currentConsultation = payload;
            })
            .addCase(getConsultationFromFacture.fulfilled, (state, {payload}) => {
                state.currentConsultation = payload;
            })
            .addCase(getFacture.fulfilled, (state, {payload}) => {
                detailsFactures(state, payload);
            })
            .addCase(getFactureFromConsultation.fulfilled, (state, {payload}) => {
                detailsFactures(state, payload);
            })
            .addCase(getAllFactures.fulfilled, (state, { payload }) => {
                state.factures = payload;
            })
            .addCase(getAllVentes.fulfilled, (state, { payload }) => {
                state.factures = payload;
            })
    }
});


export const {setCurrentConsultation,clearConsultations,clearCurrentConsultation, setSavingConsultationState, setCurrentFacture, resetCurrentFacture, setSelectedFacture, resetFactures, setFactureInfo} = consultationSlice.actions;
export default consultationSlice.reducer;